"use client"

import clsx from "clsx"

import type { ExplicitAny } from "~/@types/generics"
import errorConfig from "~/config/not-found.json"
import { geti18nLocale } from "~/lib/i18n/utils/get-processed-locale"
import useLocale from "~/hooks/useLocale"
import Error from "~/components/ui/Error"

import { neue, secondFont } from "~/styles/fonts"
import { themeClass } from "~/styles/theme.css"
import { customVariablesNames } from "~/styles/variables/customs"

export default function NotFoundPage() {
  const locale = useLocale()
  const i18nLocale = geti18nLocale(locale)
  const DATA = errorConfig[i18nLocale]

  const theme = clsx(themeClass, secondFont.className, secondFont.variable, neue.className, neue.variable)

  return (
    <html>
      <body
        className={theme}
        style={{
          [customVariablesNames.scrollBar as ExplicitAny]: "0px",
          [customVariablesNames.scrollBarGrid as ExplicitAny]: "0px",
          [customVariablesNames.stableVh as ExplicitAny]: "1vh",
          [customVariablesNames.vh as ExplicitAny]: "1vh",
          [customVariablesNames.vh100 as ExplicitAny]: "100vh",
        }}
      >
        <Error {...DATA} />
      </body>
    </html>
  )
}
